import React, { useState } from "react";
import { Button, InputText } from "primereact";
import entityService from "../../services/entity.service";

export const EntidadesPredict = ({ group, name }) => {
  const [text, setText] = useState("");
  const [resultado, setResultado] = useState({ result: "", confidence: "0" });
  const [loading, setLoading] = useState(false);

  const getPredict = () => {
    setLoading(true);
    setResultado({ result: "", confidence: "0" });

    const data = {
      group: group,
      text: text,
    };

    entityService.GetPredict(data).then((response: any) => {
      try {
        setLoading(false);
        setResultado(response.data);
      } catch (err) {
        setLoading(false);
        const error = err as { message?: string };
      }
    });
  };

  return (
    <>
      <span>
        Entidad: <strong>{name}</strong>
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "16px 0",
          boxSizing: "border-box",
        }}
      >
        <InputText
          style={{ width: "calc(100% - 92px)" }}
          id="context"
          value={text}
          onChange={(e) => setText(e.target.value)}
          required
          autoComplete="off"
          placeholder="Ingresa el texto..."
        />

        <Button
          style={{ width: "80px" }}
          label={loading ? "" : "Probar"}
          icon={loading ? "pi pi-spin pi-spinner" : ""}
          disabled={text.length === 0 || loading}
          onClick={() => getPredict()}
        />
      </div>

      <div>
        <span style={{ fontSize: "16px", marginLeft: "4px" }}>
          Resultado: <strong>{resultado.result}</strong>
        </span>
      </div>
      <div>
        <span style={{ fontSize: "16px", marginLeft: "4px" }}>
          Confidence:{" "}
          <strong style={{ fontSize: "24px" }}>
            {parseFloat(resultado.confidence) * 100}%
          </strong>
        </span>
      </div>
    </>
  );
};
