import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { viewTypes } from "../../common/types/views.type";
import { ShowView } from "../../common/components/ShowView";
import { LocutionTable } from "./LocutionTable";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { objetoAFormData } from "../../utils/objectToFormData";
import { Panel } from "../_slidePanel/Panel";
import { LocutionPanelContain } from "./LocutionPanelContain";
import locutionService from "../../services/locution.service";

interface params {
  rows: number;
  page: number;
  sortField: any;
  direction: any;
  audioName: string;
}

export default function LocutionAbm({ resetView }) {
  const { vnConfiguracion: viewName, vfAbmLocuciones: viewFunction } =
    viewTypes;
  const showViewFunc = ShowView(viewFunction, viewName);
  const userId = localStorage.getItem("user");
  const userName = localStorage.getItem("userName");

  const locutionEmpty = {
    id: "",
    audioName: "",
    bucket: "",
    userId: "",
    path: "",
  };

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locution, setLocution] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);

  const initParams: params = {
    rows: 10,
    page: 1,
    sortField: null,
    direction: null,
    audioName: "",
  };

  const [lazyParams, setLazyParams] = useState<params | null>(initParams);
  const [cleanInputFile, setCleanInputFile] = useState(null);

  const toast = useRef(null);
  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    const direction = lazyParams.direction === 1 ? "ASC" : "DESC";

    let filter = {
      audioName: lazyParams.audioName,
      rows: lazyParams.rows,
      page: lazyParams.page,
      sortField: lazyParams.sortField,
      direction: lazyParams.direction ? direction : null,
    };

    loadLazyTimeout = locutionService
      .ObtenerLocuciones(filter, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          const parsedData = response.data.locutions;
          setTotalRecords(response.data.pagination.count);
          setCustomers(parsedData);
          setLoading(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };

  const openNew = () => {
    setLocution(locutionEmpty);
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const hideDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const saveLocution = () => {
    if (locution.file) {
      const locutionObj = {
        id: null,
        audioName: locution.audioName,
        userId: userId,
        file: locution.file,
      };

      if (locution.id !== "") {
        locutionService
          .UpdateLocution(
            locution.id,
            objetoAFormData(locutionObj),
            userName,
            viewName,
            viewFunction
          )
          .then((response: any) => {
            try {
              setLocution(locutionObj);
              closePanel();
              toast.current.show({
                severity: "success",
                summary: "Cambio realizado correctamente",
                detail: "Locución modificada",
                life: 3000,
              });
              setLocution(locutionEmpty);
              loadLazyData();
            } catch (err) {
              const error = err as { message?: string };
            }
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Error en la carga",
              detail: e.response.data.message,
              life: 3000,
            });
          });
      } else {
        locutionService
          .CrearLocucion(
            objetoAFormData(locutionObj),
            userName,
            viewName,
            viewFunction
          )
          .then((response: any) => {
            try {
              setLocution(locutionObj);
              closePanel();
              toast.current.show({
                severity: "success",
                summary: "Alta realizada correctamente",
                detail: "Locución guardada",
                life: 3000,
              });
              setLocution(locutionEmpty);
              loadLazyData();
            } catch (err) {
              const error = err as { message?: string };
            }
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Error en la carga",
              detail: e.response.data.message,
              life: 3000,
            });
          });
      }
      resetErrors();
      setSubmitted(false);
      closePanel();
      setLocution(locutionEmpty);
    }
  };

  const editLocution = (locution) => {
    setLocution({ ...locution });
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const confirmDeleteLocution = (locution) => {
    setLocution({ ...locution });
    setDeleteDialog(true);
  };

  const deleteLocution = () => {
    const _id = locution.id;
    locutionService
      .borrarLocucion(_id, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          hideDeleteDialog();

          toast.current.show({
            severity: "success",
            summary: "Borrar locución",
            detail: "Eliminada correctamente",
            life: 3000,
          });
          setLocution(locutionEmpty);
          loadLazyData();
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Error en el borrado",
          detail: e.response.data.message,
          life: 3000,
        });
      });
  };

  const changeData = (name, value) => {
    if (value !== null) {
      setLocution((prevEstado) => ({
        ...prevEstado,
        [name]: value,
        audioName: value.name,
      }));
    } else {
      setLocution((prevEstado) => ({
        ...prevEstado,
        [name]: null,
        audioName: "",
      }));
    }
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };

  const initErrors = {
    audioName: null,
  };

  const [errors, setErrors] = useState(initErrors);

  const resetErrors = () => {
    setErrors(initErrors);
  };

  return (
    <>
      {showViewFunc && (
        <div className="card">
          <Toast ref={toast} />

          <LocutionTable
            customers={customers}
            initParams={initParams}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            loading={loading}
            footerPage={footerPage}
            editLocution={editLocution}
            confirmDeleteLocution={confirmDeleteLocution}
            openNew={openNew}
            resetView={resetView}
          />
          <ConfirmationDialog
            locution={locution}
            deleteDialog={deleteDialog}
            hideDeleteDialog={hideDeleteDialog}
            deleteLocution={deleteLocution}
          />
          {locution && (
            <Panel isOpen={isOpen} closePanel={closePanel}>
              <LocutionPanelContain
                locution={locution}
                saveLocution={saveLocution}
                changeData={changeData}
                cleanInputFile={cleanInputFile}
                setCleanInputFile={setCleanInputFile}
                closePanel={closePanel}
                errors={errors}
                setErrors={setErrors}
                submitted={submitted}
                setSubmitted={setSubmitted}
              />
            </Panel>
          )}
        </div>
      )}
    </>
  );
}
