import React, { useEffect } from "react";
import AuthDataService from "./common/services/auth.service";
import ConfigEnviroment from "./config/ConfigEnviroment";
import { ObtenerPermisosUser } from "./common/components/ObtenerPermisosUser";
import { Container } from "react-bootstrap";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ViewsConfig from "./components/ViewsConfig";

export default function Root(props) {
  const queryClient = new QueryClient();

  useEffect(() => {
    if (props.name === "@froneus/evi-config-voicebots") {
      AuthDataService.validate().catch((e) => {
        if (e.response.status === 401) {
          window.location.href = ConfigEnviroment.getLoginUrl();
        }
      });
    }
    ObtenerPermisosUser();
  }, [props]);

  return (
    <Container className="evi-configuration">
      <QueryClientProvider client={queryClient}>
        <ViewsConfig />
      </QueryClientProvider>
    </Container>
  );
}
