import React from "react";
import { Button, Dialog } from "primereact";

export const ConfirmationDialog = ({
  locution,
  deleteDialog,
  hideDeleteDialog,
  deleteLocution,
}) => {
  const deleteLocutionDialogFooter = (
    <>
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteLocution}
      />
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteDialog}
      />
    </>
  );

  return (
    <Dialog
      visible={deleteDialog}
      header="Borrar locución"
      modal
      footer={deleteLocutionDialogFooter}
      onHide={hideDeleteDialog}
      style={{ width: "400px", maxWidth: "95%" }}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        {locution && (
          <span>
            ¿Estas seguro de que quieres eliminar
            <br />
            <b>{locution.audioName}</b>?
          </span>
        )}
      </div>
    </Dialog>
  );
};
