import http from "../common/components/http-common";
import { IEntityType } from "../types/entity.type";

class EntityService {
  ObtenerEntities(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: {
        display_name: data.display_name,
        context: data.context,
        userId: data.userId,
        size: data.rows,
        page: data.page,
        sortBy: data.sortField,
        direction: data.direction,
      },
    };

    return http.get<any>("/v1/entity", config);
  }

  CrearEntity(
    data: IEntityType,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.post<IEntityType>("/v1/entity", data, config);
  }

  UpdateEntity(
    id,
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.patch<IEntityType>("/v1/entity/" + id, data, config);
  }

  GetPredict(data) {
    return http.post<IEntityType>(
      "/api/opciones_poll_assigner/predict?authenticated=true",
      data
    );
  }
}

export default new EntityService();
