import React, { useEffect } from "react";
import { Button, InputText, Toolbar, classNames } from "primereact";
import BodyWSItems from "./BodyWSItems";

export const BodyPanelContain = ({
  body,
  saveBody,
  onInputChange,
  closePanel,
  errors,
  setErrors,
  submitted,
  setSubmitted,
  retornoList,
}) => {
  useEffect(() => {
    submitted && validateForm();
  }, [body]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!body.nombre.trim()) {
      newErrors.nombre = "Ingresa el nombre";
      valid = false;
    } else {
      newErrors.nombre = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle BodyWS</div>;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveBody();
    }
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="field">
          <label htmlFor="nombre">Nombre</label>
          <InputText
            id="nombre"
            value={body.nombre}
            onChange={(e) => onInputChange(e, "nombre")}
            required
            autoFocus
            autoComplete="off"
            className={classNames({
              "p-invalid": submitted && errors.nombre,
            })}
            placeholder="Ingresa el nombre"
          />
          {errors.nombre && (
            <small className="p-error block">{errors.nombre}</small>
          )}
        </div>

        <div className="sep"></div>
        <div className="field">
          <BodyWSItems list={body.atributos} retornoList={retornoList} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
