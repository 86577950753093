import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { viewTypes } from "../../common/types/views.type";
import { ShowView } from "../../common/components/ShowView";
import { IIntentionType } from "../../types/intention.type";
import intentionService from "../../services/intention.service";
import { IntencionesTable } from "./IntencionesTable";
import { IntencionesPanelContain } from "./IntencionesPanelContain";
import { Panel } from "../_slidePanel/Panel";

interface params {
  rows: number;
  page: number;
  sortField: any;
  direction: any;
  nombre: string;
  userId: string;
}

export default function IntencionesAbm({ resetView }) {
  const { vnConfiguracion: viewName, vfAbmIntenciones: viewFunction } =
    viewTypes;
  const userName = localStorage.getItem("userName");
  const showViewFunc = ShowView(viewFunction, viewName);
  const userId = localStorage.getItem("user");

  const intentionEmpty = {
    id: "",
    nombre: "",
    userId: userId,
    entities: [],
  };

  const [loading, setLoading] = useState(false);
  const [intention, setIntention] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);

  const initParams = {
    rows: 10,
    page: 1,
    sortField: null,
    direction: null,
    nombre: "",
    userId: userId,
  };

  const [lazyParams, setLazyParams] = useState<params | null>(initParams);

  const toast = useRef(null);

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    const direction = lazyParams.direction === 1 ? "ASC" : "DESC";

    let filter = {
      nombre: lazyParams.nombre,
      userId: lazyParams.userId,
      rows: lazyParams.rows,
      page: lazyParams.page,
      sortField: lazyParams.sortField,
      direction: lazyParams.direction ? direction : null,
      field: lazyParams.sortField,
    };

    loadLazyTimeout = intentionService
      .ObtenerIntenciones(filter, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data.intentions.map((c) => {
            let parsed: IIntentionType = {
              id: c.id,
              nombre: c.nombre,
              userId: c.userId,
              entities: c.entities,
            };

            return parsed;
          });
          setTotalRecords(response.data.pagination.count);
          setCustomers(parsedData);
          setLoading(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };

  const openNew = () => {
    setIntention(intentionEmpty);
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const saveIntention = () => {
    const intentionObj: IIntentionType = {
      id: null,
      nombre: intention.nombre,
      userId: intention.userId,
      entities: intention.entities || [],
    };

    if (intention.id != "") {
      intentionService
        .UpdateIntencion(
          intention.id,
          intentionObj,
          userName,
          viewName,
          viewFunction
        )
        .then((response: any) => {
          try {
            setIntention(intentionObj);
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Cambio realizado correctamente",
              detail: "Intención modificada",
              life: 3000,
            });
            setIntention(intentionEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    } else {
      intentionService
        .CrearIntencion(intentionObj, userName, viewName, viewFunction)
        .then((response: any) => {
          try {
            setIntention(intentionObj);
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Alta realizada correctamente",
              detail: "Intención guardada",
              life: 3000,
            });
            setIntention(intentionEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    }
    resetErrors();
    setSubmitted(false);
    closePanel();
    setIntention(intentionEmpty);
  };

  const editIntention = (intention) => {
    setIntention({ ...intention });
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const retornoList = (lista) => {
    setIntention({ ...intention, entities: lista });
  };

  const onInputChange = (e, name) => {
    let val = e.target && e.target.value;

    let _intention = { ...intention };
    _intention[`${name}`] = val;
    setIntention(_intention);
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };

  const initErrors = {
    display_name: null,
    context: null,
  };

  const [errors, setErrors] = useState(initErrors);

  const resetErrors = () => {
    setErrors(initErrors);
  };

  return (
    <>
      {showViewFunc && (
        <div className="card">
          <Toast ref={toast} />
          <IntencionesTable
            customers={customers}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            initParams={initParams}
            loading={loading}
            footerPage={footerPage}
            openNew={openNew}
            editIntention={editIntention}
            resetView={resetView}
          />
          {intention && (
            <Panel isOpen={isOpen} closePanel={closePanel}>
              <IntencionesPanelContain
                intention={intention}
                saveIntention={saveIntention}
                onInputChange={onInputChange}
                closePanel={closePanel}
                errors={errors}
                setErrors={setErrors}
                submitted={submitted}
                setSubmitted={setSubmitted}
                retornoList={retornoList}
              />
            </Panel>
          )}
        </div>
      )}
    </>
  );
}
