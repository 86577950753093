import { Dropdown } from "primereact";
import React, { useEffect, useState } from "react";
import { viewTypes } from "../../common/types/views.type";
import bodyService from "../../services/body.service";

export const ComboManager = ({ tipo, retornoTipo }) => {
  useEffect(() => {
    getTypes();
  }, []);

  const { vnConfiguracion: viewName, vfAbmBodyWs: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  const [tipos, setTipos] = useState([]);
  const [selectedType, setSelectedType] = useState();

  const getTypes = () => {
    bodyService
      .GetAllTiposBWS(userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data.bodyAttributes;
          setTipos(parsedData);
          setTipoInicial(parsedData);
        } catch (err) {
          const error = err as { message?: string };
        }
      });
  };

  const setTipoInicial = (types) => {
    setSelectedType(types.find((t) => t.valor === tipo));
  };

  const handleChange = (type) => {
    setSelectedType(type);
    retornoTipo(type.valor);
  };

  return (
    <>
      <label htmlFor="tipo" style={{ marginTop: "8px" }}>
        Tipo
      </label>
      {tipos && (
        <Dropdown
          value={selectedType}
          onChange={(e) => handleChange(e.value)}
          options={tipos}
          optionLabel="nombre"
          style={{ marginTop: "8px" }}
          placeholder="Seleciona un tipo..."
        />
      )}
    </>
  );
};
