import BodyWSAbm from "./bodyWs/BodyWSAbm";
import EntidadesAbm from "./entity/EntidadesAbm";
import IntencionesAbm from "./intention/IntencionesAbm";
import LocutionAbm from "./locution/LocutionAbm";

export default function ViewsConfig() {
  const resetView = () => {
    window.location.href = "/configuration";
  };
  const optionView = sessionStorage.getItem("optionView");

  const selectView = () => {
    switch (optionView) {
      case "Locuciones":
        return <LocutionAbm resetView={resetView} />;
      case "Intenciones":
        return <IntencionesAbm resetView={resetView} />;
      case "Entidades":
        return <EntidadesAbm resetView={resetView} />;
      case "BodyWS":
        return <BodyWSAbm resetView={resetView} />;
      default:
        return <NullView />;
    }
  };

  return <>{selectView()}</>;
}

const NullView = () => {
  return <></>;
};
