import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { viewTypes } from "../../common/types/views.type";
import { ShowView } from "../../common/components/ShowView";
import entityService from "../../services/entity.service";
import { IEntityType } from "../../types/entity.type";
import { EntidadesTable } from "./EntidadesTable";
import { PredictDialog } from "./PredictDialog";
import { EntidadesPanelContain } from "./EntidadesPanelContain";
import { Panel } from "../_slidePanel/Panel";

interface params {
  rows: number;
  page: number;
  sortField: any;
  direction: any;
  display_name: string;
  context: string;
  userId: string;
}

export default function EntidadesAbm({ resetView }) {
  const { vnConfiguracion: viewName, vfAbmEntidades: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");
  const showViewFunc = ShowView(viewFunction, viewName);
  const userId = localStorage.getItem("user");

  const entityEmpty = {
    id: "",
    display_name: "",
    constext: "",
    userId: userId,
    options: [],
  };

  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [predictDialog, setPredictDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);
  const initParams = {
    rows: 10,
    page: 1,
    sortField: null,
    direction: null,
    display_name: "",
    context: "",
    userId: userId,
  };
  const [lazyParams, setLazyParams] = useState<params | null>(initParams);

  const toast = useRef(null);

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    const direction = lazyParams.direction === 1 ? "ASC" : "DESC";

    let filter = {
      display_name: lazyParams.display_name,
      context: lazyParams.context,
      userId: lazyParams.userId,
      rows: lazyParams.rows,
      page: lazyParams.page,
      sortField: lazyParams.sortField,
      direction: lazyParams.direction ? direction : null,
      field: lazyParams.sortField,
    };

    loadLazyTimeout = entityService
      .ObtenerEntities(filter, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data.entities.map((c) => {
            let parsed: IEntityType = {
              id: c.id,
              display_name: c.display_name,
              userId: c.userId,
              options: c.options,
              context: c.context,
            };

            return parsed;
          });
          setTotalRecords(response.data.pagination.count);
          setCustomers(parsedData);
          setLoading(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };

  const openNew = () => {
    setEntity(entityEmpty);
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const openPredictDialog = (entity) => {
    setEntity(entity);
    setPredictDialog(true);
  };

  const hidePredictDialog = () => {
    setPredictDialog(false);
  };

  const saveEntity = () => {
    const entityObj: IEntityType = {
      id: null,
      display_name: entity.display_name,
      userId: entity.userId,
      context: entity.context,
      options: entity.options || [],
    };

    if (entity.id != "") {
      entityService
        .UpdateEntity(entity.id, entityObj, userName, viewName, viewFunction)
        .then((response: any) => {
          try {
            setEntity(entityObj);
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Cambio realizado correctamente",
              detail: "Entidad modificada",
              life: 3000,
            });
            setEntity(entityEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    } else {
      entityService
        .CrearEntity(entityObj, userName, viewName, viewFunction)
        .then((response: any) => {
          try {
            setEntity(entityObj);
            closePanel();
            toast.current.show({
              severity: "success",
              summary: "Alta realizada correctamente",
              detail: "Entidad guardada",
              life: 3000,
            });
            setEntity(entityEmpty);
            loadLazyData();
          } catch (err) {
            const error = err as { message?: string };
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error en la carga",
            detail: e.response.data.message,
            life: 3000,
          });
        });
    }
    resetErrors();
    setSubmitted(false);
    closePanel();
    setEntity(entityEmpty);
  };

  const editEntity = (entity) => {
    setEntity({ ...entity });
    resetErrors();
    setSubmitted(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const onInputChange = (e, name) => {
    let val = e.target && e.target.value;

    let _entity = { ...entity };
    _entity[`${name}`] = val;
    setEntity(_entity);
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const retornoList = (lista) => {
    setEntity({ ...entity, options: lista });
  };

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };

  const initErrors = {
    display_name: null,
    context: null,
  };

  const [errors, setErrors] = useState(initErrors);

  const resetErrors = () => {
    setErrors(initErrors);
  };

  return (
    <>
      {showViewFunc && (
        <div className="card">
          <Toast ref={toast} />
          <EntidadesTable
            customers={customers}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            initParams={initParams}
            loading={loading}
            footerPage={footerPage}
            openNew={openNew}
            editEntity={editEntity}
            openPredictDialog={openPredictDialog}
            resetView={resetView}
          />
          {entity && (
            <PredictDialog
              entity={entity}
              predictDialog={predictDialog}
              hidePredictDialog={hidePredictDialog}
            />
          )}
          {entity && (
            <Panel isOpen={isOpen} closePanel={closePanel}>
              <EntidadesPanelContain
                entity={entity}
                saveEntity={saveEntity}
                onInputChange={onInputChange}
                closePanel={closePanel}
                errors={errors}
                setErrors={setErrors}
                submitted={submitted}
                setSubmitted={setSubmitted}
                retornoList={retornoList}
              />
            </Panel>
          )}
        </div>
      )}
    </>
  );
}
