import React, { useState } from "react";
import { Chip } from "primereact/chip";
import { Card } from "primereact/card";
import { InputText } from "primereact";
import { Button } from "primereact/button";

export const ChipsManager = ({ chips, retornoChips }) => {
  const [palabras, setPalabras] = useState(chips);
  const [chip, setChip] = useState("");

  const handleChange = (e) => {
    setChip(e.target.value);
  };

  const addChip = () => {
    if (chip !== "") {
      let _palabras = palabras.filter((itm) => itm !== chip);
      setPalabras([..._palabras, chip]);
      retornoChips([..._palabras, chip]);
      setChip("");
    }
  };

  const removeChip = (cp) => {
    let _palabras = palabras.filter((itm) => itm !== cp);
    setPalabras([..._palabras]);
    retornoChips([..._palabras]);
  };

  return (
    <>
      <Card style={{ width: "100%" }}>
        <label htmlFor="sinonimos">Sinónimos</label>

        <div style={{ display: "flex", padding: "15px 0 20px" }}>
          <InputText
            value={chip}
            id="chip"
            onChange={(e) => handleChange(e)}
            required
            autoComplete="off"
            placeholder="Agregar..."
          />
          <Button
            icon="pi pi-plus"
            style={{ marginLeft: "-4px" }}
            onClick={() => addChip()}
          />
        </div>

        {palabras.map((c) => (
          <Chip
            style={{ margin: "4px" }}
            key={c}
            label={c}
            removable
            onRemove={() => removeChip(c)}
          />
        ))}
      </Card>
    </>
  );
};
