import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

const IntencionesItems = ({ list, retornoList }) => {
  const itemEmpty = {
    key: "",
    nombre: "",
    _id: "",
  };

  const [intemDialog, setItemDialog] = useState(false);
  const [item, setItem] = useState(itemEmpty);
  const [lista, setLista] = useState([]);

  useEffect(() => {
    buildLista();
  }, [list]);

  const buildLista = () => {
    let _lista = [];

    list.forEach((itm) => {
      _lista.push({
        nombre: itm.nombre,
        key: itm.key,
        _id: getClave(9),
      });
    });

    setLista(_lista);
  };

  const hideDialog = () => {
    setItemDialog(false);
  };

  const llamarCallback = () => {
    let _lista = lista;

    _lista = lista.filter((itm) => itm._id !== item._id);
    _lista.push(item);

    setLista(_lista);
    setItemDialog(false);

    retornoList(_lista);
  };

  const editRow = (data) => {
    setItem({ ...data });
    setItemDialog(true);
  };

  const openNew = () => {
    setItem(itemEmpty);
    setItemDialog(true);
  };

  const deleteRow = (data) => {
    setLista(lista.filter((itm) => itm._id !== data._id));
    retornoList(lista.filter((itm) => itm._id !== data._id));
  };

  const onInputChange = (e, name) => {
    let val = e.target && e.target.value;

    let _item = { ...item };
    _item[`${name}`] = val;

    _item._id = item._id === "" ? getClave(9) : item._id;
    setItem(_item);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions" style={{ display: "flex" }}>
        <Button
          style={{ width: "22px", height: "22px" }}
          icon="pi pi-pencil"
          className="p-button-success mr-2"
          onClick={() => editRow(rowData)}
        />
        <Button
          style={{ width: "22px", height: "22px" }}
          icon="pi pi-trash"
          className="p-button-danger mr-2"
          onClick={() => deleteRow(rowData)}
        />
      </div>
    );
  };

  const intemsDialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={llamarCallback}
      />
    </>
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          width: "100%",
        }}
      >
        <label htmlFor="entidades">Entidades</label>
        <Button
          icon="pi pi-plus"
          style={{ width: "22px", height: "22px", fontSize: ".5rem" }}
          onClick={openNew}
        />
      </div>

      {lista && (
        <div style={{ border: "1px solid #e9ecef", boxSizing: "border-box" }}>
          <DataTable
            value={lista}
            responsiveLayout="scroll"
            dataKey="id"
            stripedRows
            emptyMessage="Sin resultados"
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            rows={5}
          >
            <Column
              field="nombre"
              style={{ padding: "10px" }}
              showFilterMenu={false}
              header="Nombre"
              alignHeader="left"
            />
            <Column
              bodyStyle={{ textAlign: "end", justifyContent: "end" }}
              style={{ width: "70px", padding: "10px" }}
              body={actionBodyTemplate}
            />
          </DataTable>

          <Dialog
            visible={intemDialog}
            style={{ width: "600px", maxWidth: "95%" }}
            header="Entidad"
            modal
            className="p-fluid"
            footer={intemsDialogFooter}
            onHide={hideDialog}
          >
            {item ? (
              <div className="flex flex-column align-items-left">
                <div className="field flex flex-column">
                  <label htmlFor="nombre">Nombre</label>
                  <InputText
                    id="nombre"
                    value={item.nombre}
                    onChange={(e) => onInputChange(e, "nombre")}
                    autoComplete="off"
                    autoFocus
                    placeholder="Ingresa el nombre..."
                  />
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </Dialog>
        </div>
      )}
    </>
  );
};

const getClave = (plength) => {
  var chars = "abcdefghijklmnopqrstubwsyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
  var clave = "";
  for (let i = 0; i < plength; i++) {
    clave += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return clave;
};

export default IntencionesItems;
