import http from "../common/components/http-common";

class LocutionService {
  ObtenerLocuciones(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: {
        audioName: data.audioName,
        size: data.rows,
        page: data.page,
        sortBy: data.sortField,
        direction: data.direction,
      },
    };

    return http.get<any>("/v1/locution/", config);
  }

  borrarLocucion(id, userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };

    return http.delete<any>("/v1/locution/" + id, config);
  }

  CrearLocucion(
    data: any,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };

    return http.post<any>("/v1/locution/", data, config);
  }

  UpdateLocution(
    id,
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.put<any>("/v1/locution/" + id, data, config);
  }

  obtenerAudioFile(
    id,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      responseType: "blob" as const,
    };

    return http.get<any>("/v1/locution/audio/" + id, config);
  }
}

export default new LocutionService();
