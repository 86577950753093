import { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import mime from "mime";

const FileUploader = ({
  file,
  cleanInputFile,
  setCleanInputFile,
  changeData,
  audioName,
}) => {
  const toast = useRef(null);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!file) {
      fileInputRef.current.value = "";
    }
  }, [cleanInputFile]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const isValid = await validateFile(file);
      if (isValid) {
        changeData("file", file);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "El archivo debe ser .wav",
          life: 3000,
        });
      }
    }
  };

  const validateFile = (file) => {
    // return new Promise((resolve) => {
    //   const fileExtension = file.name.split(".").pop();
    //   const mimeType = mime.getType(fileExtension);

    //   const isValid = mimeType === "audio/wav";

    //   resolve(isValid);
    // });
    return true;
  };

  const handleTrash = () => {
    changeData("file", null);
    setCleanInputFile(!cleanInputFile);
  };

  return (
    <div className="file-upload-container">
      <Toast ref={toast} />
      <div className="button-upload">
        <label htmlFor="file-input" className="file-input-label">
          <span className="file-input-icon">
            <i className="pi pi-plus"></i>
          </span>
          {file
            ? file.name
            : audioName === ""
            ? "Importar archivo.wav"
            : "Reemplazar archivo"}
        </label>
        {file && (
          <button className="trash" onClick={() => handleTrash()}>
            <i className="pi pi-trash"></i>
          </button>
        )}
      </div>

      <input
        id="file-input"
        type="file"
        onChange={handleFileUpload}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
    </div>
  );
};

export default FileUploader;
