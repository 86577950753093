import React, { useEffect } from "react";
import { Button, InputText, Toolbar, classNames } from "primereact";
import EntidadesItems from "./EntidadesItems";

export const EntidadesPanelContain = ({
  entity,
  saveEntity,
  onInputChange,
  closePanel,
  errors,
  setErrors,
  submitted,
  setSubmitted,
  retornoList,
}) => {
  useEffect(() => {
    submitted && validateForm();
  }, [entity]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!entity.display_name) {
      newErrors.display_name = "Ingresa el nombre";
      valid = false;
    } else {
      newErrors.display_name = null;
    }

    if (!entity.context) {
      newErrors.context = "Ingresa el contexto";
      valid = false;
    } else {
      newErrors.context = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle entidad</div>;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveEntity();
    }
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="field">
          <label htmlFor="display_name">Nombre</label>
          <InputText
            id="display_name"
            value={entity.display_name}
            onChange={(e) => onInputChange(e, "display_name")}
            required
            autoFocus
            autoComplete="off"
            className={classNames({
              "p-invalid": submitted && errors.display_name,
            })}
            placeholder="Ingresa el nombre"
          />
          {errors.display_name && (
            <small className="p-error block">{errors.display_name}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="context">Contexto de búsqueda</label>
          <InputText
            id="context"
            value={entity.context}
            onChange={(e) => onInputChange(e, "context")}
            required
            autoComplete="off"
            className={classNames({
              "p-invalid": submitted && errors.context,
            })}
            placeholder="Ingresa el contexto"
          />
          {errors.context && (
            <small className="p-error block">{errors.context}</small>
          )}
        </div>

        <div className="sep"></div>
        <div className="field">
          <EntidadesItems list={entity.options} retornoList={retornoList} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
