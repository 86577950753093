export const objetoAFormData = (
  objeto,
  formData = new FormData(),
  ruta = ""
) => {
  for (let clave in objeto) {
    if (objeto.hasOwnProperty(clave)) {
      const valor = objeto[clave];
      const claveForm = ruta ? `${ruta}.${clave}` : clave;

      if (Array.isArray(valor)) {
        valor.forEach((elemento, indice) => {
          const claveFormArray = `${claveForm}[${indice}]`;

          if (typeof elemento === "object" && elemento !== null) {
            objetoAFormData(elemento, formData, claveFormArray);
          } else {
            formData.append(claveFormArray, elemento);
          }
        });
      } else if (valor instanceof File) {
        formData.append(claveForm, valor);
      } else if (typeof valor === "object" && valor !== null) {
        objetoAFormData(valor, formData, claveForm);
      } else {
        formData.append(claveForm, valor);
      }
    }
  }

  return formData;
};
