import React, { useEffect } from "react";
import FileUploader from "./FileUploader";
import { Button, Toolbar } from "primereact";

export const LocutionPanelContain = ({
  locution,
  saveLocution,
  changeData,
  cleanInputFile,
  setCleanInputFile,
  closePanel,
  errors,
  setErrors,
  submitted,
  setSubmitted,
}) => {
  useEffect(() => {
    submitted && validateForm();
  }, [locution]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!locution.audioName.trim()) {
      newErrors.audioName = "Selecciona un audio";
      valid = false;
    } else {
      newErrors.audioName = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle locución</div>;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveLocution();
    }
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="field">
        {locution.audioName !== "" && (
          <div className="field">
            Audio: <strong>{locution.audioName}</strong>
          </div>
        )}

        <FileUploader
          file={locution.file}
          cleanInputFile={cleanInputFile}
          setCleanInputFile={setCleanInputFile}
          changeData={changeData}
          audioName={locution.audioName}
        />

        {errors.audioName && (
          <small className="p-error block">{errors.audioName}</small>
        )}
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
