import http from "../common/components/http-common";
import { IIntentionType } from "../types/intention.type";

class IntentionService {
  ObtenerIntenciones(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: {
        nombre: data.nombre,
        userId: data.userId,
        size: data.rows,
        page: data.page,
        sortBy: data.sortField,
        direction: data.direction,
      },
    };

    return http.get<any>("/v1/intention", config);
  }

  CrearIntencion(
    data: IIntentionType,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.post<IIntentionType>("/v1/intention", data, config);
  }

  UpdateIntencion(
    id,
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.patch<IIntentionType>("/v1/intention/" + id, data, config);
  }
}

export default new IntentionService();
