import React, { useEffect } from "react";
import { Button, InputText, Toolbar, classNames } from "primereact";
import IntencionesItems from "./IntencionesItems";

export const IntencionesPanelContain = ({
  intention,
  saveIntention,
  onInputChange,
  closePanel,
  errors,
  setErrors,
  submitted,
  setSubmitted,
  retornoList,
}) => {
  useEffect(() => {
    submitted && validateForm();
  }, [intention]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!intention.nombre.trim()) {
      newErrors.nombre = "Ingresa el nombre";
      valid = false;
    } else {
      newErrors.nombre = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle intención</div>;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveIntention();
    }
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="field">
          <label htmlFor="nombre">Nombre</label>
          <InputText
            id="nombre"
            value={intention.nombre}
            onChange={(e) => onInputChange(e, "nombre")}
            required
            autoFocus
            autoComplete="off"
            className={classNames({
              "p-invalid": submitted && errors.nombre,
            })}
            placeholder="Ingresa el nombre"
          />
          {errors.nombre && (
            <small className="p-error block">{errors.nombre}</small>
          )}
        </div>

        <div className="sep"></div>

        <div className="field">
          <IntencionesItems
            list={intention.entities}
            retornoList={retornoList}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
