import React from "react";
import { Button, Dialog } from "primereact";
import { EntidadesPredict } from "./EntidadesPredict";

export const PredictDialog = ({ entity, predictDialog, hidePredictDialog }) => {
  const predictDialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hidePredictDialog}
      />
    </>
  );

  return (
    <Dialog
      visible={predictDialog}
      header="Probar opción"
      modal
      footer={predictDialogFooter}
      onHide={hidePredictDialog}
      style={{ width: "480px", maxWidth: "95%" }}
    >
      <EntidadesPredict group={entity.id} name={entity.display_name} />
    </Dialog>
  );
};
