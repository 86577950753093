import http from "../common/components/http-common";
import { IBodyType } from "../types/body.type";

class BodyService {
  ObtenerBodies(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: {
        nombre: data.nombre,
        userId: data.userId,
        size: data.rows,
        page: data.page,
        sortBy: data.sortField,
        direction: data.direction,
      },
    };

    return http.get<any>("/v1/body", config);
  }

  CrearBody(
    data: IBodyType,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.post<IBodyType>("/v1/body", data, config);
  }

  UpdateBody(
    id,
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.patch<IBodyType>("/v1/body/" + id, data, config);
  }

  GetAllTiposBWS = (
    userName: string,
    viewName: string,
    viewFunction: string
  ) => {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.get<any>("/v1/bodyAttribute");
  };
}

export default new BodyService();
